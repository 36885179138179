import React from "react";
import "./sidebar.css";
import {
  LineStyle,
  Timeline,
  TrendingUp,
  PermIdentity,
  ProductionQuantityLimits,
  AttachMoney,
  ReportGmailerrorred,
  MailOutline,
  VoiceChat,
  Forum,
  ManageAccounts,
  AutoGraph,
  BugReport,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

function SideBar() {
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <Link to="/" className="sidelink">
              <li className="sidebarListItem">
                <LineStyle className="sidebaricon" />
                Home
              </li>
            </Link>
            <li className="sidebarListItem">
              <Timeline className="sidebaricon" />
              Analitics
            </li>
            <li className="sidebarListItem">
              <TrendingUp className="sidebaricon" />
              Sales
            </li>
          </ul>
        </div>

        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Quick menu</h3>
          <ul className="sidebarList">
          <Link to="/users" className="sidelink">
            <li className="sidebarListItem">
              <PermIdentity className="sidebaricon" />
              Users
            </li>
            </Link>
            <Link to="/product" className="sidelink">
            <li className="sidebarListItem">
              <ProductionQuantityLimits className="sidebaricon" />
              Products
            </li>
            </Link>
          
            <li className="sidebarListItem">
              <AttachMoney className="sidebaricon" />
              Transaction
            </li>
            <li className="sidebarListItem">
              <ReportGmailerrorred className="sidebaricon" />
              Reports
            </li>
          </ul>
        </div>

        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Notificatios</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <MailOutline className="sidebaricon" />
              Mail
            </li>
            <li className="sidebarListItem">
              <VoiceChat className="sidebaricon" />
              Feedback
            </li>
            <li className="sidebarListItem">
              <Forum className="sidebaricon" />
              Messages
            </li>
          </ul>
        </div>

        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Staff</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <ManageAccounts className="sidebaricon" />
              Manage
            </li>
            <li className="sidebarListItem">
              <AutoGraph className="sidebaricon" />
              Analytics
            </li>
            <li className="sidebarListItem">
              <BugReport className="sidebaricon" />
              Reports
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
