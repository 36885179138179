import React from "react";
import "./home.css";
import FeaturedInfo from "../../featuredinfo/FeaturedInfo";
import Chart from "../../chart/Chart";
import { DataUser } from "../../../data";
import WidgetLg from "../../WidgetLg/WidgetLg";
import WidgetSm from "../../WidgetSm/WidgetSm";


function Home() {
  return (
    <div className="home">
   
        <FeaturedInfo />
        <Chart title="User Analytics" data={DataUser} dataKey="User" grid />
        <div className="homewidget">
          <WidgetSm />
          <WidgetLg />
        </div>
       

    </div>
  );
}

export default Home;
