import React from "react";
import { Link } from "react-router-dom";
import Chart from "../chart/Chart";
import "./product.css";
import { productData } from "../../data";
import airpod from "./../Topbar/img/airpod.jpg";
import { Publish } from "@mui/icons-material";
import NewProduct from "../NewProduct/NewProduct";

function Product() {
  return (
    <div className="product">
      <div className="productTitleContainer">
        <h1 className="productTitle">Product</h1>
        <Link to="/newproduct">
          <button className="productAddButton">Create</button>
        </Link>
      </div>
      <div className="productTop">
        <div className="productTopLeft">
          <Chart data={productData} dataKey="Sales" title="Sales performance" />
        </div>
        <div className="productTopRight">
          <div className="productInoTop">
            <img src={airpod} alt="airpod" className="productInfoImg" />
            <span className="productName">Apple Airpod</span>
          </div>
          <div className="productIfoBottem">
            <div className="productInfoItem">
              <span className="productInfoKey">id : </span>
              <span className="productInfoValue">12</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">sales : </span>
              <span className="productInfoValue">1523</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">active : </span>
              <span className="productInfoValue">yes</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">in stock : </span>
              <span className="productInfoValue">no</span>
            </div>
          </div>
        </div>
      </div>
      <div className="productBottem">
        <form className="productForm">
          <div className="productFormLeft">
            <lable>Product Name</lable>
            <input type="text" placeholder="Apple Airpod" />
            <lable>In stock</lable>
            <select name="inStock" id="inStock">
              <option value="yes">yes</option>
              <option value="no">no</option>
            </select>
            <lable>Active</lable>
            <select name="active" id="active">
              <option value="yes">yes</option>
              <option value="no">no</option>
            </select>
          </div>
          <div className="productFormRight">
            <div className="productUpload">
              <img src={airpod} name="airpod" className="productUploadImg" />
              <lable htmlFor="file">
                <Publish />
              </lable>
              <input type="file" id="file" style={{ display: "none" }} />
            </div>
            <button className="productButton">Update</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Product;
