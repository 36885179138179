import React from "react";
import "./widgetLg.css";
import sara from "./../Topbar/img/sara.jpg";
import Product from "../Product/Product";

function WidgetLg() {
  const Button = ({ type }) => {
    return <button className={"widgetLgButton " + type}>{type}</button>;
  };

  return (
    <div className="widgetLg">
      <h3 className="widgetLgTitle">Lates Transaction</h3>
      <table className="widgetLgTable">
        <tr className="widgetLgTr">
          <th className="widgetLgTh">Customer</th>
          <th className="widgetLgTh">Date</th>
          <th className="widgetLgTh">Amount</th>
          <th className="widgetLgTh">Status</th>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <img src={sara} alt="user" className="widgetLgImg" />
            <span className="widgetLgName">Sara Ahmadi</span>
          </td>
          <td className="widgetLgDate">2 Apr 2021</td>
          <td className="widgetLgDate">$150,000</td>
          <td className="widgetLgDate">
            <Button type="Approved" />
          </td>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <img src={sara} alt="user" className="widgetLgImg" />
            <span className="widgetLgName">Sara Ahmadi</span>
          </td>
          <td className="widgetLgDate">2 Apr 2021</td>
          <td className="widgetLgDate">$150,000</td>
          <td className="widgetLgDate">
            <Button type="Declined" />
          </td>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <img src={sara} alt="user" className="widgetLgImg" />
            <span className="widgetLgName">Sara Ahmadi</span>
          </td>
          <td className="widgetLgDate">2 Apr 2021</td>
          <td className="widgetLgDate">$150,000</td>
          <td className="widgetLgDate">
            <Button type="Pending" />
          </td>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <img src={sara} alt="user" className="widgetLgImg" />
            <span className="widgetLgName">Sara Ahmadi</span>
          </td>
          <td className="widgetLgDate">2 Apr 2021</td>
          <td className="widgetLgDate">$150,000</td>
          <td className="widgetLgDate">
            <Button type="Approved" />
          </td>
        </tr>
      </table>
    </div>
  );
}

export default WidgetLg;
