import React, { useState } from "react";
import "./productlist.css";
import sara from "./../Topbar/img/sara.jpg";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@mui/icons-material";
import { rowsProduct } from "../../data";
import { Link } from "react-router-dom";

function ProductList() {
  const [data, setData] = useState(rowsProduct);

  const hnlderClick = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const columns = [
    { field: "id", headerName: "ID", width: 130 },
    {
      field: "product",
      headerName: "Product",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="ProductListUser">
            <img className="ProductListImg" src={params.row.img} />
            {params.row.name}
          </div>
        );
      },
    },
    { field: "stock", headerName: "Stock", width: 200 },
    { field: "status", headerName: "status", width: 150 },
    { field: "price", headerName: "Price", width: 200 },
    {
      field: "action",
      headerName: "action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/product/${params.row.id}`}>
              <button className="productlistEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="productlistDelete"
              onClick={() => hnlderClick(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="productList">
      <DataGrid
        disableSelectionOnClick
        rows={data}
        columns={columns}
        pageSize={5}
        checkboxSelection
      />
    </div>
  );
}

export default ProductList;
