import React from "react";
import "./users.css";
import UserList from "../UserList/UserList";
import sara from "./../Topbar/img/sara.jpg";
import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

function Users() {
  return (
    <div className="users-cnt">
      <div className="userTitleContaier">
        <h1 className="userTitle">Edit User</h1>
       <Link to='/newuser'>
       <button className="userAddButton">Create</button>
       </Link>
      </div>
      <div className="userContaier">
        <div className="userShow">
          <div className="userShowTop">
            <img src={sara} alt="user" className="userShowImg" />
            <div className="userShowTopTitle">
              <span className="userShowUsername">Sara Ahmadi</span>
              <span className="userShowUserTitle">Software Engineer</span>
            </div>
          </div>
          <div className="userShowBottom">
            <span className="userShowTitle">Account Details</span>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">Saraahmadi99</span>
            </div>
            <div className="userShowInfo">
              <CalendarToday className="userShowIcon" />
              <span className="userShowInfoTitle">10.6.1999</span>
            </div>
            <span className="userShowTitle">Contact Details</span>
            <div className="userShowInfo">
              <PhoneAndroid className="userShowIcon" />
              <span className="userShowInfoTitle">+1 234 567 89</span>
            </div>
            <div className="userShowInfo">
              <MailOutline className="userShowIcon" />
              <span className="userShowInfoTitle">Saraahmadi@gmail.com</span>
            </div>
            <div className="userShowInfo">
              <LocationSearching className="userShowIcon" />
              <span className="userShowInfoTitle">Tehran | Mirdamad</span>
            </div>
          </div>
        </div>
        <div className="userUpdate">
            <span className="userUpdateTitle">Edit</span>
            <form className="userUpdateForm">
                <div className="userUpdateLeft">
                    <div className="userUpdateItem">
                        <label>Username</label>
                        <input type='text' placeholder="saraahmadi99" className="userUpdateInput" />
                    </div>
                    <div className="userUpdateItem">
                        <label>Full Name</label>
                        <input type='text' placeholder="Sara Ahmadi" className="userUpdateInput" />
                    </div>
                    <div className="userUpdateItem">
                        <label>Email</label>
                        <input type='text' placeholder="Saraahmadi@gmail.com" className="userUpdateInput" />
                    </div>
                    <div className="userUpdateItem">
                        <label>Phone</label>
                        <input type='text' placeholder="+1 234 567 89" className="userUpdateInput" />
                    </div>
                    <div className="userUpdateItem">
                        <label>Address</label>
                        <input type='text' placeholder="Tehran | Mirdamad" className="userUpdateInput" />
                    </div>
                </div>
                <div className="userUpdateRight">
                    <div className="userUpdateUpload" >
                        <img src={sara} alt='sara' className="userUpdateImg"/>
                        <label htmlFor="file"><Publish className="userUpdateIcon"/></label>
                        <input type='file' id='file' style={{display: 'none'}} />
                    </div>
                    <button className="userUpdateButton">Update</button>
                </div>
            </form>
        </div>
      </div>
    </div>
  );
}

export default Users;
