import { Visibility } from "@mui/icons-material";
import React from "react";
import "./widgetsm.css";
import sara from "./../Topbar/img/sara.jpg";

function WidgetSm() {
  return (
    <div className="widgetSm">
      <h3 className="widgetSmTitle">New Join Members</h3>
      <li className="widgesmlistitem">
        <img src={sara} className="widgetSmImg" alt="prf" />
        <div className="widgetsmuser">
          <span className="widgetsmusername">Sara Ahmadi</span>
          <span className="widgetsmusertitle">Software Engineer</span>
        </div>
        <button className="widgetsmbutton">
          <Visibility className="widgetsmicon" />
          Display
        </button>
      </li>
      <li className="widgesmlistitem">
        <img src={sara} className="widgetSmImg" alt="prf" />
        <div className="widgetsmuser">
          <span className="widgetsmusername">Sara Ahmadi</span>
          <span className="widgetsmusertitle">Software Engineer</span>
        </div>
        <button className="widgetsmbutton">
          <Visibility className="widgetsmicon" />
          Display
        </button>
      </li>
      <li className="widgesmlistitem">
        <img src={sara} className="widgetSmImg" alt="prf" />
        <div className="widgetsmuser">
          <span className="widgetsmusername">Sara Ahmadi</span>
          <span className="widgetsmusertitle">Software Engineer</span>
        </div>
        <button className="widgetsmbutton">
          <Visibility className="widgetsmicon" />
          Display
        </button>
      </li>
      <li className="widgesmlistitem">
        <img src={sara} className="widgetSmImg" alt="prf" />
        <div className="widgetsmuser">
          <span className="widgetsmusername">Sara Ahmadi</span>
          <span className="widgetsmusertitle">Software Engineer</span>
        </div>
        <button className="widgetsmbutton">
          <Visibility className="widgetsmicon" />
          Display
        </button>
      </li>
      <li className="widgesmlistitem">
        <img src={sara} className="widgetSmImg" alt="prf" />
        <div className="widgetsmuser">
          <span className="widgetsmusername">Sara Ahmadi</span>
          <span className="widgetsmusertitle">Software Engineer</span>
        </div>
        <button className="widgetsmbutton">
          <Visibility className="widgetsmicon" />
          Display
        </button>
      </li>
    </div>
  );
}

export default WidgetSm;
