import React from "react";
import "./topbar.css";
import { NotificationsNone, Settings, Language } from "@mui/icons-material";
import profile from "./../Topbar/img/profile.jpg";

function TopBar() {
  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topleft">
          <span className="logo">Logo</span>
        </div>

        <div className="topright">
          <div className="topbariconcontainer">
            <NotificationsNone />
            <span className="topbariconbadge">2</span>
          </div>
          <div className="topbariconcontainer">
            <Language />
          </div>
          <div className="topbariconcontainer">
            <Settings />
          </div>
          <img src={profile} alt="profile" className="topright-img" />
        </div>
      </div>
    </div>
  );
}

export default TopBar;
