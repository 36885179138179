import React from "react";
import TopBar from "./component/Topbar/TopBar";
import SideBar from "./component/Topbar/SideBar";
import "./App.css";
import Home from "./component/Page/Home/Home";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import Product from "./component/Product/Product";
import NewProduct from "./component/NewProduct/NewProduct";
import Users from "./component/Users/Users";
import UserList from "./component/UserList/UserList";
import NewUser from "./component/NewUser/NewUser";
import ProductList from "./component/ProductList/ProductList";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <div>
          <TopBar />
          <div className="container">
            <SideBar />

            <Route exact path="/" component={Home} />
            <Route path="/users" component={UserList} />
            <Route exact path="/product" component={ProductList} />
            <Route exact path="/product/:productId" component={Product} />
            <Route exact path="/user/:userId" component={Users} />
            <Route exact path="/newuser" component={NewUser} />
            <Route exact path="/newproduct" component={NewProduct} />
          </div>
        </div>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
