import mac from "./component/Topbar/img/mac.jpg";
import sara from "./component/Topbar/img/sara.jpg";
import iphone from "./component/Topbar/img/iphone.jpg";
import airpod from "./component/Topbar/img/airpod.jpg";

export const DataUser = [
  {
    name: "Jan",
    User: 1800,
  },
  {
    name: "Feb",
    User: 3000,
  },
  {
    name: "March",
    User: 2000,
  },
  {
    name: "APril",
    User: 2780,
  },
  {
    name: "May",
    User: 1890,
  },
  {
    name: "June",
    User: 2390,
  },
  {
    name: "July",
    User: 3390,
  },
  {
    name: "Aug",
    User: 1400,
  },
  {
    name: "Oct",
    User: 2000,
  },
  {
    name: "Nov",
    User: 1800,
  },
  {
    name: "Dec",
    User: 3900,
  },
];

export const rowsUser = [
  {
    id: 1,
    username: "sara ahmadi",
    avatar: sara,
    email: "sara.a@gmail.com",
    status: "active",
    transaction: "$120.000",
  },
  {
    id: 2,
    username: "sahar ahmadi",
    avatar: sara,
    email: "sahar.a@gmail.com",
    status: "active",
    transaction: "$120.000",
  },
  {
    id: 3,
    username: "ali ahmadi",
    avatar: sara,
    email: "sahar.a@gmail.com",
    status: "active",
    transaction: "$120.000",
  },
  {
    id: 4,
    username: "mehdi ahmadi",
    avatar: sara,
    email: "sahar.a@gmail.com",
    status: "active",
    transaction: "$120.000",
  },
  {
    id: 5,
    username: "sahar ahmadi",
    avatar: sara,
    email: "sahar.a@gmail.com",
    status: "active",
    transaction: "$120.000",
  },
  {
    id: 6,
    username: "sara ahmadi",
    avatar: sara,
    email: "sara.a@gmail.com",
    status: "active",
    transaction: "$120.000",
  },
  {
    id: 7,
    username: "sahar ahmadi",
    avatar: sara,
    email: "sahar.a@gmail.com",
    status: "active",
    transaction: "$120.000",
  },
  {
    id: 8,
    username: "ali ahmadi",
    avatar: sara,
    email: "sahar.a@gmail.com",
    status: "active",
    transaction: "$120.000",
  },
  {
    id: 9,
    username: "mehdi ahmadi",
    avatar: sara,
    email: "sahar.a@gmail.com",
    status: "active",
    transaction: "$120.000",
  },
  {
    id: 10,
    username: "sahar ahmadi",
    avatar: sara,
    email: "sahar.a@gmail.com",
    status: "active",
    transaction: "$120.000",
  },
];

export const productData = [
  {
    name: "Jan",
    Sales: 1800,
  },
  {
    name: "Feb",
    Sales: 3000,
  },
  {
    name: "March",
    Sales: 2000,
  },
  {
    name: "APril",
    Sales: 2780,
  },
];

export const rowsProduct = [
  {
    id: 1,
    name: "Apple MacBook",
    img: mac,
    stock: 52,
    status: "active",
    price: "$50.00",
  },
  {
    id: 2,
    name: "Apple Airpod",
    img: airpod,
    stock: 32,
    status: "active",
    price: "$150.00",
  },
  {
    id: 3,
    name: "Apple Iphone",
    img: iphone,
    stock: 45,
    status: "active",
    price: "$250.00",
  },
  {
    id: 4,
    name: "Apple MacBook",
    img: mac,
    stock: 80,
    status: "active",
    price: "$50.00",
  },
  {
    id: 5,
    name: "Apple Airpod",
    img: airpod,
    stock: 15,
    status: "active",
    price: "$150.00",
  },
  {
    id: 6,
    name: "Apple Iphone",
    img: iphone,
    stock: 23,
    status: "active",
    price: "$250.00",
  },
  {
    id: 7,
    name: "Apple MacBook",
    img: mac,
    stock: 41,
    status: "active",
    price: "$50.00",
  },
  {
    id: 8,
    name: "Apple Airpod",
    img: airpod,
    stock: 48,
    status: "active",
    price: "$150.00",
  },
  {
    id: 9,
    name: "Apple Iphone",
    img: iphone,
    stock: 4,
    status: "active",
    price: "$250.00",
  },
  {
    id: 10,
    name: "Apple MacBook",
    img: mac,
    stock: 52,
    status: "active",
    price: "$50.00",
  },
];
