import React from "react";
import "./newproduct.css";

function NewProduct() {
  return (
    <div className="newproduct">
      <h1 className="addproducttitle">New Product</h1>
      <form className="addproductform">
        <div className="addproductitem">
          <label>Image</label>
          <input type="file" id="file" />
        </div>
        <div className="addproductitem">
          <label>Name</label>
          <input type="text" placeholder="Apple Airpod" />
        </div>
        <div className="addproductitem">
          <label>Stock</label>
          <input type="text" placeholder="123" />
        </div>
        <div className="addproductitem">
          <label>Active</label>
          <select name="active" id="active">
            <option value="yes">yes</option>
            <option value="no">no</option>
          </select>
        </div>
        <button className="addcreatebutton">Create</button>
      </form>
    </div>
  );
}

export default NewProduct;
